import  React, { Component } from 'react';
export default class BaseContainer extends Component {
    
    appleStore = () => {
        window.location = 'https://apps.apple.com/vn/app/pantograph-networking-app/id1504033778'
    }

    googlePlay = () => {
        window.location = 'https://play.google.com/store/apps/details?id=pantograph.bacoor.crypto.co'
    }

    apkDownload = () => {
        window.location = 'https://github.com/bacoor-hb/pantograph-wallet/releases/download/latest/app-release.apk'
    }

    renderDownLoad = () => {
        return (
            <div className="buttonGroup">
                <img className="btnStoreItem" src={require('./images/appstore.png')} alt='appStore' onClick={this.appleStore} />
                <img className="btnStoreItem" src={require('./images/googleplay.png')} alt='googlePlay' onClick={this.googlePlay} />
                <img className="btnStoreItem" src={require('./images/apkdownload.png')} alt='apkDownload' onClick={this.apkDownload} />
            </div>
        )

    }

}