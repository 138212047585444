import React from 'react';
import {
    isMobile,
    isMobileSafari
} from "react-device-detect";
import './styles/global.css';
import BaseContainer from './BaseContainer'
import ChatServices from './api/ChatServices'
import { GET } from './api/ChatServices'
let currentLink = "https://pantograph.io/"
var QRCode = require('qrcode.react');
export default class User extends BaseContainer {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            data: null,
        }
        this.anchorElement = null;
    }

    async componentDidMount() {
        const { userId } = this.props.match.params
        try {
            let data = await ChatServices.requestData(GET, process.env.REACT_APP_LINK_USER, userId)
            this.setState({ isLoading: false, data: data });
        } catch {
            this.setState({
                isLoading: false,
                data: null
            })
        }
        const winHeight = document.getElementById('html').clientHeight;
        const boxHeight = document.getElementById('abc').clientHeight;
        if (winHeight > boxHeight) {
            let margin = (winHeight - boxHeight) / 2 - 50;
            margin = margin > 40 ? margin : 30;
            document.getElementById('abc').style.marginTop = (margin - 20) + 'px';
            document.getElementById('abc').style.marginBottom = (margin - 20) + 'px';
        }
    }


    redirectLink = () => {
        let currentLink = "https://pantograph.io/"
        if (!isMobile) {
            this.anchorElement.href = currentLink;
            this.anchorElement.setAttribute('target', '_blank')
            // window.open(currentLink, "_blank")
        } else {
            try {
                const { userId } = this.props.match.params
                // Detect Pantograph App
                if (window.web3 && window.web3.eth && window.web3.eth.accounts[0]) {
                    this.anchorElement.href = "pantograph://profileId/" + userId;
                    window.postMessage({ type: 'open_profile', data: { id: userId } })
                } else {
                    this.timeout = setTimeout(function () {
                        window.location = currentLink;
                    }, isMobileSafari ? 25 : 3000)
                    // window.location = "hbwallet://profileId/" + userId;
                    this.anchorElement.href = "pantograph://profileId/" + userId;
                }
            } catch {
                // window.location = currentLink;
                this.anchorElement.href = currentLink;
            }
        }
    }

    render() {
        const { isLoading, data } = this.state
        return (

            <div>
                <div className="imgContainer">
                    <img id="hbIconImg" src={require('./images/pantoicon.png')} alt='icon' width="50px" />
                </div>
                <div className="clear"></div>
                <div className="backgroundContainer" >
                    {isLoading ?
                        <div className="infoContainer" id="abc">
                            <img id="profileImg" src={require('./images/noProfile.png')} alt='profileImg' />
                            <p className="element"><img src={require('./images/loading.gif')} width="30px" alt='loadingGif' /></p>
                            <div className="text ellipsis">
                                <span className="text-concat">
                                    {'Loading user information...'}
                                </span>
                            </div>

                            <a href={currentLink} ref={(el) => { if (el) { this.anchorElement = el; } }} rel="nofollow" className="pointer">
                                <button id="btnWeb" onClick={this.redirectLink} rel="nofollow" className="pointer">{isMobile ? 'More details' : 'Open Pantograph App website'}</button>
                            </a>

                            {this.renderDownLoad()}
                        </div>
                        : data ?
                            <div className="infoContainer" id="abc">
                                <img id="profileImg" src={`${data && data.image ? data.image : require('./images/noProfile.png')}`} alt='profileImg' />
                                <p className="element">{data.username}</p>
                                <div className="hbText">
                                    {data.address}
                                </div>
                                <div className='auto-center MT10 MB10'>
                                    <QRCode
                                        id="1"
                                        value={'https://profile.pantograph.app'  + this.props.match.url}
                                        size={100}
                                        level={"H"}
                                        includeMargin={true}
                                    />
                                </div>
                                {/* <button id="btnWeb" onClick={this.redirectLink} rel="nofollow">{isMobile ? 'More details' : 'Open Pantograph App website'}</button> */}
                                <a ref={(el) => { if (el) { this.anchorElement = el; } }} rel="nofollow" className="pointer MT10">
                                    <button id="btnWeb" onClick={this.redirectLink} rel="nofollow" className="pointer">{isMobile ? <div className="des-mobile-div"><span className="first-detail-line">More details</span> <br /> <span className="second-detail-line">Please tap this button after installed Pantograph App </span> </div> : 'Open Pantograph App website'}</button>
                                </a>
                                {this.renderDownLoad()}
                            </div>
                            : <div className="infoContainer" id="abc">
                                <img id="profileImg" src={require('./images/noProfile.png')} alt='profileImg' />
                                <p className="element">{'Profile Not Found'}</p>
                                <div className="textAll">
                                    <span className="text-concat">
                                        {'This user’s ID cannot be found.'}
                                    </span>
                                </div>
                                {/* <button id="btnWeb" onClick={this.redirectLink} rel="nofollow">{isMobile ? 'More details' : 'Open Pantograph App website'}</button> */}
                                <a href={currentLink} ref={(el) => { if (el) { this.anchorElement = el; } }} rel="nofollow" className="pointer MT10">
                                    <button id="btnWeb" onClick={this.redirectLink} rel="nofollow" className="pointer">{isMobile ? <div className="des-mobile-div"><span className="first-detail-line">More details</span> <br /> <span className="second-detail-line">Please tap this button after installed Pantograph App </span> </div> : 'Open Pantograph App website'}</button>
                                </a>
                                {this.renderDownLoad()}
                            </div>
                    }
                </div>
                <div id="bottomWrapper">
                    <img id="bottomImage" src={require('./images/bacoor.png')} alt='companyIcon' width="100px" />
                </div>
            </div>
        );
    }
}
