import React from 'react'
import BaseContainer from './BaseContainer'
import './styles/global.css';
import {
    isMobile
} from "react-device-detect";
let currentLink = "https://pantograph.io/"
export default class Main extends BaseContainer {
    constructor(props) {
        super(props)
        this.anchorElement = null;
    }

    redirectLink = () => {
        let currentLink = "https://pantograph.io/"
        if (isMobile) {
            window.location = currentLink
        } else {
            // window.open(currentLink, "_blank")
            this.anchorElement.href = currentLink;
            this.anchorElement.setAttribute('target', '_blank')
        }
    }

    componentDidMount() {


        const winHeight = document.getElementById('html').clientHeight;
        const boxHeight = document.getElementById('abc').clientHeight;
        if (winHeight > boxHeight) {
            let margin = (winHeight - boxHeight) / 2 - 50;
            margin = margin > 40 ? margin : 30;
            document.getElementById('abc').style.marginTop = (margin) + 'px';
            document.getElementById('abc').style.marginBottom = margin - 30 + 'px';
        }
    }
    render() {
        return (
            <div className="mainInfo">
                <div className="imgContainer">
                    <img id="hbIconImg" src={require('./images/pantoicon.png')} alt='icon' width="50px" />
                </div>
                <div className="clear"></div>
                <div className="backgroundContainer" >
                    <div className="infoContainer" id="abc">
                        <img id="profileDefaultImg" src={require('./images/panto.png')} alt='profileImg' />
                        <p className="element">{'PANTOGRAPH'}</p>
                        <p className="hbText">
                            {'Welcome to PANTOGRAPH'}
                        </p>
                        <a href={currentLink} ref={(el) => { if (el) { this.anchorElement = el; } }} rel="nofollow" className="pointer">
                            <button id="btnWeb" onClick={this.redirectLink} rel="nofollow" className="pointer">{isMobile ? 'More details' : 'Open Pantograph App website'}</button>
                        </a>
                        {this.renderDownLoad()}
                    </div>

                </div>
                <div id="bottomWrapper">
                    <img src={require('./images/bacoor.png')} alt='companyIcon' width="100px" />
                </div>
            </div>


        );
    }
}
